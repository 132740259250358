<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="result"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="false"
      :addable="false"
    ></lineFieldsArray>
    <input type="hidden" :value="JSON.stringify(this.value)" :name="field" />
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  props: ["result", "fieldAttributes", "field","value"],
  components: { lineFieldsArray },
  // data() {
  //   return { manegers: this.result.additional.managersArray };
  // },
  // watch: {
  //   "result.valueLine.c_organisation": function (value) {
  //     console.log("changed", value);

  //     let activeProbeOrganisationValue = value;
  //     let caller = this;
  //     this.frameworkAxiosRequest({
  //       method: "post",
  //       url: this.result.tableUrl,
  //       data: {
  //         id: activeProbeOrganisationValue,
  //         requestType: "ajax",
  //         function: "getOrganisationManager",
  //       },
  //     })
  //       .then(function (response) {
  //         caller.manegers = response.data.result.json.managersArray;
  //       })
  //       .catch(function (response) {
  //         //handle error
  //         console.log(response);
  //       });
  //   },
  // },

  computed: {
    fields() {
      let fields = {
        value: {
          type: "combobox",

          name: this.fieldAttributes.name,
          fieldAttrInput: this.fieldAttributes.fieldAttrInput,
          // options: this.manegers,
          inputFieldFormatter: "manegersFormatter",
        },

        remove: lineButtons.remove,
        add: lineButtons.add,
      };
      return fields;
    },
  
    pairArray: {
      get() {
        let pairArray = [];

        if (
          typeof this.value != "undefined" &&
          Object.keys(this.value).length != 0 &&
          this.value != null
        ) {
          console.log(this.value);
          this.value.forEach((value) => {
            pairArray.push({ value: value });
          });
        } else {
          pairArray = [{ value: "" }];
        }
        return pairArray;
      },
      set(newValue) {
        console.log("new value", newValue);
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    checkInput(value) {
      console.log(value);
      let sentValue = [];
      let update = true;
      // let caller = this;
      value.forEach(function (info) {
        if (typeof sentValue[info.value] != "undefined") {
          update = false;
          return;
        }
        sentValue.push(info.value);
      });
      if (update) {
        //this.$emit("input", sentValue);
        this.$emit("input", sentValue);
      }
    },
  },
};

// $(function(){
//    $(document).on("change","select.activeProbeOrganisation",function(){
//      // changing the mangagers according to the orgnaisation
//      let activeProbeOrganisationValue=this.value
//      let $activeProbeOrganisation=$(this);
//        $.ajax({
//             type: "POST",
//             async: false,
//             dataType: 'json',
//             data: { id:activeProbeOrganisationValue ,requestType:"ajax",function:"getOrganisationManager" } ,
//             //url: "serve.php?f=administration&f2=superUserAdministration",
//             success: function (data) {
//            let $fieldObject= $activeProbeOrganisation.closest(".fields");
//            $fieldObject.data("organisationManagers",data);
//            $fieldObject.find("select.managerSelector").each (function(){
//            let $managerSelector=$(this);
//               destroyComboBox($managerSelector.parent());
//               $managerSelector.html(produceOptions(data, "",""));
//               initializeCombobox($managerSelector.parent());
//              })
//             }
//           });

//      });
//    })
//   let value=v-if="typeof $attributes.value)&& $attributes.value!=""}{json_encode($attributes.value!='undefined'"{else}[""]{/if} ;
//   let managersArray={json_encode(result.additional.managersArray)};
//   let  $fieldObject=$("#{$id}").closest(".fields");
//   $fieldObject.data("organisationManagers",managersArray);
//   increment=0;

//   function addManager(that,value)
//   {
//     if (typeof value=="undefined"){
//       value="";
//     }
//     increment++;
//     let $caller=$(that);
//     let  $fieldObject=$caller.closest(".fields");
//     let  managersArray=  $fieldObject.data("organisationManagers");
//     /*  "managerSelector comboBox"*/
//     let $informationContainer=$caller.closest('.activeProbsManagersContainer').find('.informationContainer');
//     let selectorAttributes={json_encode(fieldAttributes["fieldAttrInput"])};
//     selectorAttributes["name"]="{$field}["+increment+"]";
//     let managerSelector =makeSelector(managersArray, value,selectorAttributes);
//     let $managerLine=$('<div class="managerLine informationLine"></div>').appendTo($informationContainer);
//     $(managerSelector).appendTo($managerLine);
//     $('<a href="javascript:void(0);" onclick="addManager(this)"><i title="Add" class="fas fa-plus-square fs16" aria-hidden="true"></i></a>').appendTo($managerLine);
//     let $removeButtomContainer=$("<div class='inlineDiv removeContainer'></div>").appendTo($managerLine);
//     redrawAllDeleteButtons($informationContainer);
//     initializeCombobox($managerLine);
//     resizeForm($fieldObject);
//   }

//   $(function(){
//     var $scriptContainer=$("#{$id}");
//     $.each(value, function (index,val){
//       addManager($scriptContainer,val);
//     });
//     let $fieldsObject=$scriptContainer.closest(".fields");

//     registerRemoveInfoInputChange ($fieldsObject)

//   });
</script>